"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    expose: [],
    setup: function (__props) {
        var t = (0, vue_3.ref)(5);
        var i = (0, vue_3.ref)(null);
        var s = "HELLO";
        var toggle = function () {
            if (i.value) {
                clearInterval(i.value);
                i.value = null;
            }
            else {
                i.value = setInterval(function () { return t.value = t.value + 1; }, 1000);
            }
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Fragment, null, [
                (0, vue_2.createVNode)("div", null, (0, vue_2.toDisplayString)((0, vue_2.unref)(s)) + " world " + (0, vue_2.toDisplayString)(t.value), 1),
                (0, vue_2.createVNode)("button", {
                    onClick: _cache[1] || (_cache[1] = function ($event) { return (toggle()); }),
                    style: { color: i.value == null ? 'green' : 'red' }
                }, (0, vue_2.toDisplayString)(i.value == null ? "start" : "stop"), 5)
            ], 64));
        };
    }
});
